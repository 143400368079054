import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

import styles from '../MaintenanceScreen/maintenance.module.scss'

const MaintenanceScreen = () => {
  return (
    <Fragment>
      <Container fluid className="bg-black p-0">
        <div className={styles.mainMaintenance}>
          <div className={styles.bgimg}>
            {/* <div className={styles.topleft}>
              <p>
                <img src="logo/cp_text_logo.png" />
              </p>
            </div> */}
            {/* <div className={styles.middle}>
                    <h1>UNDER CONSTRUCTION</h1>
                    <hr/>
                    <p>35 days left</p>
                </div> */}
            {/* <div className={styles.bottomleft}>
              <p>
                We are performing essential upgrades to our system, and the platform will be temporarily offline, We
                apologize for the inconvenience and will be back online shortly.
                <br /> Thank you for your patience!
              </p>
            </div> */}
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default MaintenanceScreen
